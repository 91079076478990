<template>
  <div class="container">
    <h1 class="titlePage titlePage_textLeft">WELCOME <span class="text-capitalize">{{ getUserName }}</span>!</h1>
    <!--<h2 class="text text_italic ">Please select from the following!</h2>-->
    <div class="row tw-items-start">

      <router-link :to="buildTo(item)"
                   class="col-md-2 col-sm-4 col-4 py-4 tw-flex tw-flex-col tw-items-center tw-justify-center"
                   v-for="item in getVisibleItemsOnly"
                   :key="item.id"
      >
        <svg-icon :name="item.img" class="small_icon"/>
        <span class="thumbnailsTable__text">{{item.text}}</span>
      </router-link>

      <a class="col-md-2 col-sm-4 col-4 py-4 tw-flex tw-flex-col tw-items-center tw-justify-center"
         :href="item.link"
         v-for="item in getTargetAvailableItemsOnly"
         :target="item.target"
      >
        <svg-icon :name="item.img" class="small_icon"/>
        <span class="thumbnailsTable__text">{{item.text}}</span>
      </a>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'Headline',
    computed: {
      ...mapGetters({
        user: 'userList/user',
        items: 'itemsList/items'
      }),

      getTargetAvailableItemsOnly() {
        const items = this.items
        return  _.filter(items,(item) => {
              return item.target
        })
      },
      getVisibleItemsOnly: function () {
        return _.filter(this.items, (item) => {
          var v = true;
          v = v && !item.target;

          if (item.admin)
            v = v && localStorage.getItem('user_role') === 'admin';

          return v
        })

      },
      getUserName() {
          return localStorage.getItem('nameUser');
      },



    },

    methods:{
        isVisible(item){

            var v = true;

            v = v && !item.target;

            if (item.admin)
                v = v & localStorage.getItem('user_role') === 'admin';

            return v;
        },
      buildTo(item) {
        let o = {name: item.link.name};

        if (item.query) {
          let q = {};
          q[item.query.name] = item.query.value
          o.query = q
        }
        if (item.params){
          o.params = item.params
        }

        return o
      },
    }
  };
</script>

<style scoped lang="scss">

</style>
